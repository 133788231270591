.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

ul{
  list-style: none;
  padding: 0;
}

/* .companyLogo{
  max-width: 300px;
  margin: 10px;
} */

.graduatedCompanies{
  display: flex;
  flex-direction: column;
  align-items: center;
    /* justify-content: center;
  align-items: center;
  margin: 0 auto; */
}

.graduatedCompanies .company {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  border-radius: 15px;
  margin: 20px;
  padding: 10px;
  width: 250px;
}

.graduatedCompanies .company .companyLogo {
  width: 100%;
  max-height: 120px;
}

.logoContainer {
  height: 120px;
  width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description{
  height: 80px;
  width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graduatedCompanies .company .companyLogo img{
  width: 100%;
}

@media(min-width: 600px){
  .graduatedCompanies {
      display: flex;
      flex-direction: row;
      max-width: 600px;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
} 